<template>

  <div class="row" ref="conferenceVideoForm">

    <div class="col-xs-12 col-md-12 col-sm-12">
      <ValidationObserver v-slot="{ handleSubmit }" ref="formValidator">

        <card>
          <div slot="header">
            <h4 class="card-title">
              {{ formTitle }}
            </h4>
          </div>

          <div class="card-body">
            <div class="row">
              <div class="col-md-12 col-sm-12">
                <div class="row">
                 <div class="col-md-12">
                   <ValidationProvider
                           vid="title"
                           rules="required"
                           name="The Title"
                           v-slot="{ passed, failed,errors }">
                     <fg-input type="text"
                               :error="failed ? errors[0]: null"
                               label="Title"
                               name="title"
                               fou
                               v-model="formData.title">
                     </fg-input>
                   </ValidationProvider>
                   <ValidationProvider
                     vid="video_type"
                     rules=""
                     name="The Video Type"
                     v-slot="{ passed, failed,errors }">
                     <fg-select
                       name="video_type"
                       size="large"
                       filterable
                       clearable
                       placeholder="Video Type"
                       :error="failed ? errors[0]: null"
                       :input-classes="'select-default'"
                       :label="'Video Type'"
                       :list="videoTypes"
                       :listItemLabel="'label'"
                       :listItemValue="'value'"
                       v-model="formData.video_type">
                     </fg-select>
                   </ValidationProvider>
                   <div class="form-group" v-if="formData.video_type === 'UPLOADED'">
                     <label>Uploaded Video</label>
                     <el-tooltip placement="right" v-if="getBannerVideoInfo()">
                       <div slot="content">{{getBannerVideoInfo()}}</div>
                       <span style="margin: 0 5px;">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-question-circle" viewBox="0 0 16 16">
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                      <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                    </svg>
                    </span>
                     </el-tooltip>
                     <prime-video-uploader
                       :preview-width="'200px'"
                       :preview-height="'200px'"
                       :maxFileSize="50000000"
                       v-model="formData.uploaded_video"
                     >
                     </prime-video-uploader>
                   </div>
                   <div class="form-group" v-if="formData.video_type == 'EMBEDDED'">
                     <el-tooltip placement="right" v-if="getBannerVideoInfo()">
                       <div slot="content">{{getBannerVideoInfo()}}</div>
                       <span style="margin: 0 5px;">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-question-circle" viewBox="0 0 16 16">
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                      <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                    </svg>
                    </span>
                     </el-tooltip>
                     <ValidationProvider
                       vid="embedded_video"
                       rules=""
                       name="The Embedded Video"
                       v-slot="{ passed, failed,errors }">
                       <fg-input type="text"
                                 :error="failed ? errors[0]: null"
                                 label="Embedded Video"
                                 name="embedded_video"
                                 fou
                                 v-model="formData.embedded_video">
                       </fg-input>
                     </ValidationProvider>
                     <div v-html="formData.embedded_video">
                     </div>
                   </div>


                 </div>
               </div>
              </div>
            </div>

          </div>

          <div class="card-footer text-right">
            <l-button :disabled="entityNotFoundError || submitting" @click.prevent="handleSubmit(submit)"
                      nativeType="submit"
                      type="info" wide>Submit
            </l-button>
            <l-button @click="$router.push('/conference-videos/list/'+ formData.lang)" type="danger" wide>Cancel
            </l-button>
          </div>
        </card>

      </ValidationObserver>

    </div>

  </div>
</template>
<script>
import {extend} from "vee-validate";
import {Select, Option,Tooltip} from 'element-ui';
import FgSelect from "@/components/Inputs/formGroupSelect";
import FormGroupTextArea, {ImageUploader, Switch as LSwitch} from "@/components";
import Editor from '@tinymce/tinymce-vue'
import {mapGetters} from "vuex";
import {PrimeUploader} from '@/components';
import {PrimeVideoUploader} from '@/components';


extend("required", {
  message: "{_field_} is required"
});

export default {

  components: {
    FormGroupTextArea,
    [Option.name]: Option,
    [Select.name]: Select,
    [Tooltip.name]: Tooltip,
    FgSelect,
    LSwitch,
    PrimeUploader,
    ImageUploader,
    PrimeVideoUploader,
    'editor': Editor
  },

  data() {
    return {
      editMode: false,
      loader: {},
      id: undefined,
      editorConfig: this.getEditorConfig(),

      formTitle: "",
      entityNotFoundError: false,
      submitting: false,
      editorKey: process.env.VUE_APP_TINY_EDITOR_KEY,

      formData: {
        title: "",
        video_type: '',
        uploaded_video: "",
        embedded_video: "",

      },
      videoTypes: [],
    };
  },

  computed: {
    ...mapGetters({
      mediaInfo: "global/mediaInfo"
    })
  },
  created(){
      const path = window.location.href;
      this.formData.lang = path.substring(path.lastIndexOf('/') + 1)
  },
  mounted() {
    this.loader = this.$loading.show({
      container: this.$refs.conferenceVideoForm
    });
    this.getBuilderData();
  },


  methods: {
    getBuilderData(){
      let data = {
        'lang': this.formData.lang,
      }
      this.axios.post("conference-videos/builder",data).then((response) => {
        this.videoTypes = response.data.videoTypes;
        this.id = this.$route.params['id'];
        if (this.id !== undefined) {
          this.editMode = true;
          this.formTitle = "Edit Video";
          this.getConferenceVideo();
        } else {
          this.editMode = false;
          this.formTitle = "Add Video";
          this.loader.hide();
        }

      }).catch((error) => {
        console.error(error);
      });
    },
    getConferenceVideo() {
      let data = {
        'id' : this.id,
        'lang' : this.formData.lang
      };
      this.axios.post("conference-videos/get",data).then((response) => {
        this.formData = response.data;
        this.loader.hide();
      }).catch((error) => {
        if (error.response.status === 404) {
          this.entityNotFoundError = true;
          this.$notify({
            message: "Video Not Found",
            timeout: 2000,
            type: 'danger'
          });
          this.loader.hide();

        } else {
          console.error(error);
        }
      })
    },


    submit() {
      let request;
      let successMessage;
      this.submitting = true;
      let formData = this.formData;
      if (formData.video_type == "UPLOADED"){
        formData.embedded_video = '';
      }else{
        formData.uploaded_video = '';
      }
      if (this.editMode === true) {
        request = this.axios.put("conference-videos/update/" + this.id, formData);
        successMessage = "Video Updated Successfully";
      } else {
        request = this.axios.post("conference-videos/create", formData);
        successMessage = "Video Added Successfully";
      }

      request.then((response) => {
        this.$notify({
          message: successMessage,
          timeout: 1000,
          type: 'success'
        })
        this.$router.push("/conference-videos/list/"+ this.formData.lang);
      }).catch((error) => {
        if (error.response.status === 422) {
          this.$refs.formValidator.setErrors(error.response.data.errors);
        } else {
          console.log(error.response)
        }
      }).finally(() => {
        this.submitting = false;
      })
    },

    getBannerImageInfo() {
      if(this.mediaInfo && this.mediaInfo["panel_image"]) {
        return this.mediaInfo["panel_image"];
      } else {
        return "";
      }
    },

    getBannerVideoInfo() {
      if(this.mediaInfo && this.mediaInfo["panel_video"]) {
        return this.mediaInfo["panel_video"];
      } else {
        return "";
      }
    }

  }
}
</script>
